<template>
  <div class="contact">
    <div class="aboutOurCompany">
      <div class="title"><h3>المناقصه</h3></div>

      <v-divider></v-divider>
      <v-container>
        <v-row>
          <h4 class="blue--text">
            شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
          </h4>
          <span class="ml-2 mr-2">/</span
          ><span class="gray--text"> مناقصه </span>
        </v-row>
        <v-row class="mt-10" v-if="items">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="9">
            <h3 v-if="items.title">
              {{ items.title }}
            </h3>

            <hr class="fade-in-right " />
            <!-- <p v-for="subText in TotalText" :key="subText">{{ subText }}</p> -->
            <span class="ql-editor" v-if="items.description" v-html="items.description"> </span>

            <h3
              class="green--text downloadText"
              v-for="(file, index) in items.tender_files"
              :key="index"
              v-show="file.type == 'pdf'"
            >              <a
                :href="
                  'https://backend.ascww.org/api/tenders/download/' + file.path
                "
                >لتحميل كراسه الشروط أضغط هنا<v-icon class="green--text">mdi-file</v-icon></a
              >
            </h3>

            <v-row
              v-for="(file, index) in items.tender_files"
              :key="index"
              v-show="file.type == 'image'"
            >
              <expandable-image
                class="mt-10"
                :src="
                  'https://backend.ascww.org/api/tenders/image/' + file.path
                "
              >
              </expandable-image>
            </v-row>
            <!--  <h3 class="green--text" v-if="items.tender_files">
              للتنزيل كراسه الشروط أضغط هنا

              <a
                :href="`${publicPath}` + './files/' + items.file_name"
                :download="items.file_name"
                ><v-icon class="green--text">mdi-file</v-icon></a
              >-->
            <!-- <a href="http://link/to/file" download="FileName">Download it!</a>  -->
            <!--  </h3>
            <expandable-image
              class="mt-10"
              v-if="items.main_image"
              :src="'https://backend.ascww.org/api/image/' + items.main_image"
            >
            </expandable-image> -->
          </v-col>

          <v-col cols="12" sm="12" xs="12" lg="3">
            <LinkX></LinkX>
          </v-col>
        </v-row>
        <v-row v-else>
          <h3>لا توجد مناقصة بهذا الرابط حاليا</h3>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import LinkX from "../components/link.vue";

export default {
  name: "Link",

  components: {
    LinkX,
  },

  data() {
    return {
      model: null,
      userId: this.$route.params.userId,
      publicPath: process.env.BASE_URL,
    };
  },

  methods: {
    checkID() {
      this.userId = this.$route.params.userId;
    },
  },
  computed: {
    items() {
      //  this.$store.dispatch('findSelectedItem',this.userId)
      return this.$store.state.allTenders.find(
        (item) => item.slug == this.userId
      );
    },
    TotalText() {
      var xx = this.items.details;
      const words = xx.split("-");
      return words;
    },
  },

  watch: {
    $route: "checkID",
  },
};
</script>
<style>
h2 {
  width: 600px !important;
}
.subtextnews {
  font-size: 15px;
}
.downloadText {
  margin-left: 100px;
  margin-right: 100px;
}
</style>
